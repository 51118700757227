/* eslint-disable react/prop-types */
import * as React from 'react';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

// eslint-disable-next-line react/prop-types
export default function NewReusableQuestionare(props) {
  const [content] = useState(props.label ? props.label : 'label');
  const [sliderValue, setSliderValue] = useState(null);
  const [textareaValue, setTextareaValue] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    window.addEventListener('questionHowMuchWouldYou', handleErrorEvent);
    return () => {
      window.removeEventListener('questionHowMuchWouldYou', handleErrorEvent);
    };
  }, []);

  const handleErrorEvent = () => {
    let questions =
      JSON.parse(sessionStorage.getItem('new-questions-lpa')) || {};
    const currentValue = questions[props.identifier];
    if (!currentValue) {
      setError(true);
    }
  };

  useEffect(() => {
    if (props.answerType === 'number') {
      let questions =
        JSON.parse(sessionStorage.getItem('new-questions-lpa')) || {};
      const currentValue = questions[props.identifier];
      if (currentValue === '') {
        setSliderValue(null);
        return;
      }
      setSliderValue(currentValue === null ? null : Number(currentValue));
    } else if (props.answerType === 'openEnded') {
      let questions =
        JSON.parse(sessionStorage.getItem('new-questions-lpa')) || {};
      const currentValue = questions[props.identifier];
      setTextareaValue(currentValue === null ? '' : currentValue);
    }
  }, []);

  const handleSliderChange = async (newValue) => {
    setSliderValue(+newValue);
    symptomDescriptionValue();
    setNewQuestion(props.identifier, +newValue);
  };

  const setNewQuestion = (identifier, value) => {
    let questions =
      JSON.parse(sessionStorage.getItem('new-questions-lpa')) || {};
    questions[identifier] = value;
    sessionStorage.setItem('new-questions-lpa', JSON.stringify(questions));
  };

  const symptomDescriptionValue = () => {
    switch (sliderValue) {
      case 0:
        return 'Never had the symptom';
      case 1:
        return 'Occasionally have it, mild effect';
      case 2:
        return 'Occasionally have it, severe effect';
      case 3:
        return 'Frequently have it, mild effect';
      case 4:
        return 'Frequently have it, severe effect';
      default:
        return '';
    }
  };

  const handleTextareaChange = (event) => {
    setTextareaValue(event.target.value);
    setNewQuestion(props.identifier, event.target.value);
  };

  const template = props.answerType === 'openEnded' ? '50% 50%' : '35% 40% 25%';

  return (
    <Box
      sx={{
        flex: '50%',
        width: '100%',
        padding: { xs: '20px 10px', sm: '10px', md: '10px' },
        display: 'grid',
        gridTemplateColumns: { xs: '100%', md: template },
        gridTemplateRows: '1fr',
        alignItems: 'center',
      }}
    >
      <Box
        className="title-questions"
        sx={{ fontSize: '14px', fontWeight: 'bolder' }}
      >
        {
          // TODO: temp solution to add enumeration to questions
        }
        {props.identifierNumericQuestion !== null && (
          <Box
            className="question-number"
            sx={{
              fontSize: '14px',
              fontWeight: 'bolder',
              textAlign: 'center',
              padding: '10px',
            }}
          >
            {`${props.identifierNumericQuestion}.`}
          </Box>
        )}
        {content}
      </Box>
      {props.answerType === 'number' && (
        <>
          <Box>
            <RadioGroup
              onChange={(event) => handleSliderChange(event.target.value)}
              row
              value={sliderValue}
              style={{
                border:
                  error && isNaN(sliderValue)
                    ? '2px solid red'
                    : '2px solid #cbcccc',
                borderRadius: '22px',
                display: 'flex',
                alignItems: 'stretch',
              }}
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                style={{
                  flex: '1',
                  backgroundColor: sliderValue === 0 ? '#85b4cd' : 'white',
                  borderRadius: '20px',
                  height: '45px',
                  color: sliderValue === 0 ? 'white' : 'black',
                  justifyContent: 'center',
                  margin: '0',
                }}
                value="0"
                control={<Radio />}
                label="0"
              />
              <FormControlLabel
                style={{
                  flex: '1',
                  backgroundColor: sliderValue === 1 ? '#85b4cd' : 'white',
                  borderRadius: '20px',
                  height: '45px',
                  color: sliderValue === 1 ? 'white' : 'black',
                  justifyContent: 'center',
                  margin: '0',
                }}
                value="1"
                control={<Radio />}
                label="1"
              />
              <FormControlLabel
                style={{
                  flex: '1',
                  backgroundColor: sliderValue === 2 ? '#85b4cd' : 'white',
                  borderRadius: '20px',
                  height: '45px',
                  color: sliderValue === 2 ? 'white' : 'black',
                  justifyContent: 'center',
                  margin: '0',
                }}
                value="2"
                control={<Radio />}
                label="2"
              />
              <FormControlLabel
                style={{
                  flex: '1',
                  backgroundColor: sliderValue === 3 ? '#85b4cd' : 'white',
                  borderRadius: '22px',
                  height: '45px',
                  color: sliderValue === 3 ? 'white' : 'black',
                  justifyContent: 'center',
                  margin: '0',
                }}
                value="3"
                control={<Radio />}
                label="3"
              />
              <FormControlLabel
                style={{
                  flex: '1',
                  backgroundColor: sliderValue === 4 ? '#85b4cd' : 'white',
                  borderRadius: '20px',
                  height: '45px',
                  color: sliderValue === 4 ? 'white' : 'black',
                  justifyContent: 'center',
                  margin: '0',
                }}
                value="4"
                control={<Radio />}
                label="4"
              />
            </RadioGroup>
          </Box>
          <Box
            className="description-questions"
            sx={{ marginLeft: '15px', fontSize: '13px' }}
          >
            {symptomDescriptionValue()}
          </Box>
        </>
      )}
      {props.answerType === 'openEnded' && (
        <Box
          className="description-questions"
          sx={{ marginLeft: '15px', fontSize: '13px' }}
        >
          <textarea
            style={{
              width: '100%',
              height: '70px',
              padding: '10px',
              borderRadius: '10px',
              border:
                error &&
                (textareaValue === null ||
                  textareaValue === '' ||
                  textareaValue === undefined)
                  ? '2px solid red'
                  : '2px solid #cbcccc',
            }}
            placeholder={props.placeholder ?? 'Please describe your symptoms'}
            value={textareaValue}
            onChange={handleTextareaChange}
          />
        </Box>
      )}
    </Box>
  );
}
