import * as React from 'react';
import Typography from '@mui/material/Typography';
import NewReusableQuestionare from './NewReusableQuestionare';

const LpaQuestionnaire = React.forwardRef((props, ref) => {
  const newQuestionEntries = Object.entries(props.assessmentData);
  const [currentStartIndex, setCurrentStartIndex] = React.useState(0);
  const questionsPerPage = 10;
  const lastIndex = newQuestionEntries.length - 1;
  // eslint-disable-next-line no-unused-vars
  const [isLastPage, setIsLastPage] = React.useState(false);

  React.useEffect(() => {
    const newIsLastPage =
      currentStartIndex + questionsPerPage >= newQuestionEntries.length;
    setIsLastPage(newIsLastPage);
    props.onIsLastPageChange(newIsLastPage);

    const newIsFirstPage = currentStartIndex === 0;
    props.onIsFirstPageChange(newIsFirstPage);
  }, [currentStartIndex, newQuestionEntries.length, questionsPerPage, props]);

  const visibleQuestions = newQuestionEntries.slice(
    currentStartIndex,
    currentStartIndex + questionsPerPage
  );

  const updateVisibleQuestions = (startIndex, endIndex) => {
    const visibleQuestions = newQuestionEntries
      .slice(startIndex, endIndex)
      .map(([, questionData]) => questionData)
      .map(([, questionData]) => questionData); // extract just the question data
    sessionStorage.setItem(
      'new-visibleQuestions',
      JSON.stringify(visibleQuestions)
    );
  };

  React.useEffect(() => {
    updateVisibleQuestions(
      currentStartIndex,
      currentStartIndex + questionsPerPage
    );
    // eslint-disable-next-line
  }, [currentStartIndex, questionsPerPage]);

  const showNextQuestions = () => {
    if (currentStartIndex + questionsPerPage < newQuestionEntries.length) {
      setCurrentStartIndex(currentStartIndex + questionsPerPage);
    }
  };

  const showPreviousQuestions = () => {
    if (currentStartIndex >= questionsPerPage) {
      setCurrentStartIndex(currentStartIndex - questionsPerPage);
    }
  };

  React.useImperativeHandle(ref, () => ({
    showNextQuestions,
    showPreviousQuestions,
  }));

  return (
    <div>
      <div
        className="question-container"
        id="general-question-container"
        style={{ paddingTop: '10px', height: 'fit-content' }}
      >
        {visibleQuestions.map(([key, value], index) => {
          const isOpenEnded = value[1].answerType === 'openEnded';
          let identifierNumericQuestion;
          // TODO: temp solution this is just a hack to add enumeration to questions skiping the open-ended indexes
          if (currentStartIndex === 0) {
            identifierNumericQuestion = index - 2;
          } else {
            identifierNumericQuestion = index + (currentStartIndex - 2);
          }
          return (
            <React.Fragment key={key}>
              <div
                style={{
                  width: isOpenEnded ? '100%' : '48%',
                  display: 'inline-block',
                  margin: isOpenEnded ? '0 0 20px 0' : '0 1%',
                  boxSizing: 'border-box',
                }}
              >
                <NewReusableQuestionare
                  key={key}
                  identifierNumericQuestion={
                    !isOpenEnded ? identifierNumericQuestion : null
                  }
                  identifier={value[1].question_id}
                  questionId={value[1].question_id}
                  value={value.value}
                  label={value[1].question}
                  answerType={value[1].answerType}
                  options={value[1].options}
                  isLast={currentStartIndex + index === lastIndex}
                  placeholder={'Description...'}
                />
              </div>
              {
                // Temp solution to place the text on top of the numeric questions, we should refactor this
              }
              {value[1].answerType === 'openEnded' && index === 2 ? (
                <Typography
                  component="p"
                  sx={{ fontSize: '18px', padding: '50px 0' }}
                >
                  Welcome to our questionnaire designed to help you assess your
                  progress. This questionnaire consists of 64 questions and
                  should take less than 5 minutes. You will need to scroll down
                  to answer all of them. For each question, please rate the
                  frequency and severity of your symptoms on a scale of 0 to 4.
                  <br />
                  <br />
                  The questions cover a range of symptoms that may be related to
                  various health conditions. By answering these questions, you
                  will provide us with valuable information that can help us
                  understand your symptoms better and provide you with more
                  personalized care.
                </Typography>
              ) : null}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
});

export default LpaQuestionnaire;
