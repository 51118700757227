import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import StepsLayout from '../../app/common/StepsLayout';
import React, { useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import LpaQuestionnaire from './Lpa-questionnaire';
import axios from 'axios';
import {
  getToken,
  getUser,
  getUserData,
  getUserSessionEmail,
} from '../../app/services/AuthService';
import { Alert } from '@mui/material';
import { getApiUrl, getUrl } from '../../app/api/agent';
import LinearProgress from '@mui/material/LinearProgress';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

export default function LpaForm() {
  const [activeStep, setActiveStep] = React.useState(0);
  const questionerRef = useRef();
  const [errorMessage, setErrorMessage] = React.useState('');
  const [messageType, setMessageType] = useState('success');
  const [isLastPage, setIsLastPage] = useState(false);
  const [isFirstPage, setIsFirstPage] = useState(true);
  const [assessmentData, setAssessmentData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [sendingLpa, setSendingLpa] = useState(false);
  const [lpaExists, setLpaExists] = useState(false);
  const userData = getUserData();
  const [userId, setUserId] = useState(userData.userId);
  const navigate = useNavigate();
  const url = getApiUrl();

  function handleIsLastPageChange(isLastPage) {
    setIsLastPage(isLastPage);
  }

  function handleIsFirstPageChange(isFirstPage) {
    setIsFirstPage(isFirstPage);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${url}/assessments/lpa`);
        const filteredQuestions = response.data.questionnaire.questions.filter(
          (question) => question.question !== 'Email'
        );

        const sortedQuestions = filteredQuestions.sort((a, b) => {
          if (a.answerType === 'openEnded' && b.answerType !== 'openEnded') {
            return -1;
          }
          if (a.answerType !== 'openEnded' && b.answerType === 'openEnded') {
            return 1;
          }
          return 0;
        });

        setAssessmentData(Object.entries(sortedQuestions));
      } catch (error) {
        console.error('Error fetching data:', error);
      }

      try {
        let tempUserId = userId;
        if (!userId) {
          const token = getToken();
          const verifyResponse = await axios.post(getUrl() + '/verify', {
            email: getUser(),
            token: token,
          });
          setUserId(verifyResponse.data.userId);
          tempUserId = verifyResponse.data.userId;
        }
        const answers = await axios.post(`${url}/assessments`, {
          action: 'getAnswers',
          assessmentType: 'LPA',
          userId: tempUserId,
        });
        if (answers.data.response.answers.length > 0) {
          setAnswers(answers.data.response.answers);
          setLpaExists(true);
        }
        setIsLoading(false);
      } catch (error) {
        console.error('No NTA for user:', error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const setAnswers = (answers) => {
    const newQuestions = {};
    answers.forEach((answer) => {
      newQuestions[answer.question_id] = answer.value;
    });
    sessionStorage.setItem('new-questions-lpa', JSON.stringify(newQuestions));
  };

  const validateLpaQuestionsPage = () => {
    const currentQuestions = JSON.parse(
      sessionStorage.getItem('new-visibleQuestions')
    );
    const currentAnswers = JSON.parse(
      sessionStorage.getItem('new-questions-lpa')
    );
    if (currentAnswers === null) {
      return false;
    }

    // Validate if all questions have been answered
    return currentQuestions.every((question) => {
      const answer = currentAnswers[question.question_id];
      return answer !== undefined && answer !== null;
    });
  };

  const handleNext = async () => {
    if (!validateLpaQuestionsPage()) {
      const validatorEvent = new CustomEvent('questionHowMuchWouldYou');
      window.dispatchEvent(validatorEvent);
      setErrorMessage(
        'Some questions are not answered, please answer all questions before submitting'
      );
      setMessageType('error');
      setTimeout(() => {
        setErrorMessage(null);
      }, 2000);
      return;
    }

    setSendingLpa(true);
    const questions =
      JSON.parse(sessionStorage.getItem('new-questions-lpa')) || {};
    const editPayload = {
      action: 'updateAnswers',
      assessmentType: 'LPA',
      userId: userId,
      answers: Object.keys(questions).map((key) => ({
        question_id: key,
        value: questions[key],
      })),
    };

    if (isLastPage) {
      try {
        await axios.patch(`${url}/assessments`, editPayload, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        setErrorMessage('Your assessment has been submitted successfully');
        setTimeout(() => {
          setSendingLpa(false);
          setErrorMessage(null);
          navigate('/');
        }, 2000);
      } catch (error) {
        setErrorMessage(
          'There was an error submitting your assessment try again later'
        );
        setMessageType('error');
        setTimeout(() => {
          setErrorMessage(null);
        }, 2000);
      }
      return;
    }
    if (lpaExists) {
      try {
        await axios.patch(`${url}/assessments`, editPayload, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        setSendingLpa(false);
        questionerRef.current.showNextQuestions();
      } catch (error) {
        console.error('Error:', error);
        setMessageType('error');
        setErrorMessage('There was an error submitting your assessment');
        setTimeout(() => {
          setErrorMessage(null);
        }, 2000);
      }
    } else {
      const createPayload = {
        userId: userId,
        answers: Object.keys(questions).map((key) => ({
          question_id: key,
          value: questions[key],
        })),
      };
      createPayload.answers.push({
        question_id: '000',
        value: getUserSessionEmail(),
      });

      try {
        await axios.post(`${url}/assessments/lpa`, createPayload, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        setSendingLpa(false);
        setLpaExists(true);
        questionerRef.current.showNextQuestions();
      } catch (error) {
        console.error('Creating Error:', error);
        setMessageType('error');
        setErrorMessage('There was an error submitting your assessment');
        setTimeout(() => {
          setErrorMessage(null);
        }, 2000);
      }
    }
  };

  const getWindowDimensions = () => {
    return window.innerWidth;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const handleBack = () => {
    questionerRef.current.showPreviousQuestions();
    setActiveStep(activeStep - 1);
  };

  return (
    <StepsLayout>
      <div style={{ marginTop: '20px' }}>
        <Box
          id="header"
          className="frame"
          style={{
            width: getWindowDimensions() - 210 + 'px',
            overflowX: 'auto',
            whiteSpace: 'nowrap',
            backgroundColor: 'white',
            padding: '0 10px 0 10px',
            marginBottom: '20px',
          }}
        ></Box>
        <Box
          id="content-questions"
          component="form"
          noValidate
          onSubmit={handleSubmit}
        >
          <Box sx={{ backgroundColor: 'white', margin: '7px' }}>
            <Box sx={{ pt: 3, pl: 3 }}>
              <Typography
                variant="h5"
                sx={{
                  pt: 2,
                  pb: 2,
                  fontWeight: 'bolder',
                  color: '#536d7b',
                  fontSize: { xs: '16px', md: '30px' },
                }}
              >
                Longevity Program Assessment
              </Typography>
            </Box>
            <hr />
            {!isLoading && (
              <LpaQuestionnaire
                onIsLastPageChange={handleIsLastPageChange}
                ref={questionerRef}
                setErrorMessage={setErrorMessage}
                setMessageType={setMessageType}
                assessmentData={assessmentData}
                onIsFirstPageChange={handleIsFirstPageChange}
              />
            )}
            {isLoading && (
              <Box sx={{ width: '100%', marginTop: '40px' }}>
                <LinearProgress />
              </Box>
            )}
          </Box>
          <Box className="forms-button-container">
            <Box
              sx={{
                display: 'flex',
                justifyContent: { xs: 'center', md: 'space-between' },
                alignItems: 'center',
              }}
            >
              {isFirstPage ? (
                <Box sx={{ flexGrow: 1 }} /> // Empty Box to take up space when isFirstPage is true
              ) : (
                <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                  &lt; Back
                </Button>
              )}
              <Button
                style={{
                  width: '200px',
                  fontSize: '17px',
                  borderRadius: '9px',
                  backgroundColor: '#85b4cd',
                  cursor: !sendingLpa ? 'pointer' : 'not-allowed',
                }}
                disabled={sendingLpa}
                variant="contained"
                onClick={handleNext}
                sx={{ mt: 3, ml: 1 }}
              >
                {sendingLpa ? (
                  <CircularProgress size={24} style={{ color: 'white' }} />
                ) : isLastPage ? (
                  'Submit'
                ) : (
                  'Next'
                )}
              </Button>
            </Box>
          </Box>
        </Box>
        <Box className="profile-error" sx={{ marginTop: '10px' }}>
          {errorMessage && <Alert severity={messageType}>{errorMessage}</Alert>}
        </Box>
      </div>
    </StepsLayout>
  );
}
