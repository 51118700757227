import { getApiKey, getUrl } from '../api/agent';
import axios from 'axios';

export const getUser = () => {
  const user = sessionStorage.getItem('user');
  if (user === 'undefined' || !user) {
    return null;
  } else {
    return user;
  }
};

export const getToken = () => {
  return sessionStorage.getItem('token');
};

export const setUserSessionEmail = (email, token) => {
  sessionStorage.setItem('user', email);
  sessionStorage.setItem('token', token);
};

export const getUserSessionEmail = () => {
  return sessionStorage.getItem('user');
};

export const getUserNTQ = () => {
  const user = JSON.parse(sessionStorage.getItem('userData'));
  if (user === 'undefined' || !user) {
    return null;
  } else {
    return user.ntq;
  }
};

export const setUserNTQ = (ntq) => {
  const currentUser = getUserData();
  currentUser.ntq = ntq;
  setUserData(currentUser);
};

export const setUserLeadScore = (lead_score) => {
  const currentUser = getUserData();
  currentUser.lead_score = lead_score;
  setUserData(currentUser);
};

export const getUserLeadScoreId = () => {
  const user = JSON.parse(sessionStorage.getItem('userData'));
  if (user === 'undefined' || !user) {
    return null;
  } else {
    return user.lead_score;
  }
};

export const setStage = (stage) => {
  const currentUser = getUserData();
  if (stage > Number(getStage())) {
    currentUser.stage = stage;
  }
  setUserData(currentUser);
};

export const setParent = (parent) => {
  const currentUser = getUserData();
  currentUser.parent = parent;
  setUserData(currentUser);
};

export const getStage = () => {
  const user = JSON.parse(sessionStorage.getItem('userData'));
  if (user === 'undefined' || !user) {
    return null;
  } else {
    return user.stage;
  }
};

export const getProduct = () => {
  const user = JSON.parse(sessionStorage.getItem('userData'));
  if (user === 'undefined' || !user) {
    return null;
  } else {
    return user.productName;
  }
};

export const setUserData = (data, setIce = false) => {
  sessionStorage.setItem('userData', JSON.stringify(data));
  if (data.ice && setIce) {
    setUpIce(data.ice).then((res) => {
      console.log(res);
    });
  }
};

export const setUpIce = async (ice) => {
  const config = {
    url: getUrl() + '/get-ice',
    method: 'post',
    data: {
      ice: ice,
    },
    headers: {
      'x-api-key': getApiKey(),
      'Content-Type': 'application/json',
    },
  };
  try {
    const response = await axios(config);
    const user = getUserData();
    user.ice = response.data.ice_result;
    setUserData(user);
    return true;
  } catch (error) {
    return true;
  }
};

export const isUserParent = () => {
  const user = JSON.parse(sessionStorage.getItem('userData'));
  if (user === 'undefined' || !user) {
    return null;
  } else {
    return typeof user.parent === 'undefined' ? true : user.parent;
  }
};

export const resetUserSession = () => {
  sessionStorage.removeItem('user');
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('userData');
  sessionStorage.removeItem('questions');
  sessionStorage.removeItem('current-ppa-questions');
  sessionStorage.removeItem('new-questions');
  sessionStorage.removeItem('new-questions-lpa');
};

export const getUserData = () => {
  return JSON.parse(sessionStorage.getItem('userData'));
};
