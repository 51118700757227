import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

export default function BasicCard({ assessment }) {
  const navigate = useNavigate();

  const goTo = () => {
    navigate(`/${assessment.id}`);
  };

  return (
    <Card sx={{ minWidth: 275, maxWidth: 400, background: 'white' }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {assessment.assessment}
        </Typography>
        <Typography variant="h5" component="div">
          {assessment.title}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {assessment.description}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          sx={{
            backgroundColor: '#888888',
            color: 'white',
            '&:hover': {
              backgroundColor: '#37374b',
            },
          }}
          onClick={goTo}
        >
          Go To Assessment
        </Button>
      </CardActions>
    </Card>
  );
}
