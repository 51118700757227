import * as React from 'react';
import Box from '@mui/material/Box';
import Login from './components/login/Login';
import AutoLogin from './components/AutoLogin/AutoLogin';
import { useCallback, useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {
  getProduct,
  getToken,
  getUser,
  resetUserSession,
  setParent,
  setStage,
  setUserData,
  setUserLeadScore,
  setUserNTQ,
  setUserSessionEmail,
} from './app/services/AuthService';
import axios from 'axios';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Home from './components/home/Home';
import { getApiKey, getApiUrl, getUrl } from './app/api/agent';
import PropTypes from 'prop-types';
import Profile from './components/profile/Profile';
import Register from './components/register/Register';
import Create from './components/create/Create';
import CreateSingle from './components/create-single/CreateSingle';
import CreateDouble from './components/create-double/CreateDouble';
import BookCall from './components/bookCall/BookCall';
import WatchVideo from './components/watchVideo/WatchVideo';
import './App.css';
import ResetPassword from './components/resetPassword/ResetPassword';
import SetPassword from './components/setPassword/setPassword';
import CreateChild from './components/create-child/CreateChild';
import ChildProfile from './components/child-profile/child-profile';
import MagicLogin from './components/MagicLogin/MagicLogin';
import RegisterSecondary from './components/registerSecondary/RegisterSecondary';
import DeleteForm from './components/deleteForm/DeleteForm';
import CreateCloseDoor from './components/create-closedoor/CreateCloseDoor';
import scriptjs from 'scriptjs';
import {
  facebookPixel,
  gtag,
  Heiser,
  simpleTracking,
  tikTokPixel,
  universalAnalytics,
  adroll,
} from './app/common/Embed/thyroid-scripts/facebook-pixel';
import {
  adrollCovid,
  facebookPixelCovid,
  gtagCovid,
  simpleTrackingCovid,
  tikTokPixelCovid,
  universalAnalyticsCovid,
} from './app/common/Embed/covid-scripts';
import AdminLogin from './components/AdminLogin/AdminLogin';
import AdminUserIndex from './components/admin-user-report/admin-user-index';
import ConsultRequirements from './components/consult-requirements/consult-requirements';
import ConsultRequirement from './components/consult-requirement/consult-requirement';
import { Time2Book } from './components/time-2-book/time-2-book';
import { Time2Book2 } from './components/time-2-book/time-2-book-2';
import Transition from './components/transition/Transition';
import WhichAppointment from './components/which-appointment/which-appointment';
import NewForms from './components/new-form/NewForm';
import LoginWithPassword from './components/LoginPassword/LoginWithPassword';
import Create30DCR from './components/create-30dcr/create-30dcr';
import CreateLite from './components/create-lite/create-lite';
import WhichAppointmentReferral from './components/which-appointment-referral/which-appointment-referral';
import { Time2BookReferral } from './components/time-2-book/time-2-book-referral';
import PpaForm from './components/ppa-form/PpaForm';
import AssessmentsHome from './assets/assessments-home/assessments-home';
import { OfficeManagerCallBooking } from './components/office-manager-call-booking/office-manager-call-booking';
import LpaForm from './components/lpa-form/LpaForm';

const PrivateRoute = ({ children }) => {
  return getUser() ? children : <Navigate to="/login" />;
};

const AuthMagicRoute = ({ children }) => {
  const [isAuthenticating, setAuthenticating] = useState(true);
  const location = useLocation();
  const magicToken = new URLSearchParams(location.search).get('magicToken');

  if (!magicToken) {
    return children;
  }

  const config = {
    url: getApiUrl() + '/verify-token',
    method: 'POST',
    data: {
      token: magicToken,
    },
    headers: {
      'x-api-key': getApiKey(),
      'Content-Type': 'application/json',
    },
  };

  axios(config)
    .then((response) => {
      const { data } = response;
      const { user } = data;
      setUserSessionEmail(user.email, magicToken);
      setUserData({
        ...user,
      });
      setStage(user.stage);
      setUserNTQ(user.ntq);
      setUserLeadScore(user.lead_score);
      setParent(user.parent);
      const url = new URL(window.location.href);
      url.searchParams.delete('magicToken');
      window.history.replaceState({}, '', url);
    })
    .catch((error) => {
      resetUserSession();
      return <Navigate to="/login" />;
    })
    .finally(() => {
      setAuthenticating(false);
    });

  if (isAuthenticating) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return children;
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

const PublicRoute = ({ children }) => {
  return getUser() ? <Navigate to="/" /> : children;
};

PublicRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function App() {
  const location = useLocation();
  const [isAuthenticating, setAuthenticating] = useState(true);

  const loadTrackingScripts = useCallback(() => {
    if (process.env.REACT_APP_ENVIRONMENT === 'local') {
      console.log('Local environment, not loading tracking scripts...');
      return;
    }
    switch (getProduct()) {
      case 'covid-single':
        loadCovidScripts();
        return;
      case 'covid-double':
        loadCovidScripts();
        return;
      case 'thyroid-single':
        loadThyroidScripts();
        return;
      case 'thyroid-double':
        loadThyroidScripts();
        return;
      default:
        return;
    }
  }, []);

  const loadThyroidScripts = () => {
    scriptjs('https://www.googletagmanager.com/gtag/js?id=AW-709290382');
    scriptjs('https://www.googletagmanager.com/gtag/js?id=G-E1CTXJQDVC');
    scriptjs('//js.hs-scripts.com/20336610.js', 'hs-script-loader');
    facebookPixel();
    tikTokPixel();
    universalAnalytics();
    simpleTracking();
    Heiser();
    gtag();
    adroll();
  };

  const loadCovidScripts = () => {
    scriptjs('//js.hs-scripts.com/20336610.js', 'hs-script-loader');
    scriptjs('https://www.googletagmanager.com/gtag/js?id=G-E1CTXJQDVC');
    facebookPixelCovid();
    universalAnalyticsCovid();
    tikTokPixelCovid();
    simpleTrackingCovid();
    gtagCovid();
    adrollCovid();
  };

  useEffect(() => {
    const token = getToken();

    if (
      token === 'undefined' ||
      token === undefined ||
      token === null ||
      !token
    ) {
      return;
    }

    const config = {
      url: getUrl() + '/verify',
      method: 'post',
      data: {
        email: getUser(),
        token: token,
      },
      headers: {
        'x-api-key': getApiKey(),
        'Content-Type': 'application/json',
      },
    };

    axios(config)
      .then((response) => {
        setUserSessionEmail(response.data.email, token);
        setStage(response.data.stage);
        setAuthenticating(false);
        setUserNTQ(response.data.ntq);
        setUserLeadScore(response.data.lead_score);
        setParent(response.data.parent);
        loadTrackingScripts();
      })
      .catch(() => {
        resetUserSession();
        setAuthenticating(false);
      });
  }, [location.pathname, loadTrackingScripts]);

  const token = getToken();
  if (isAuthenticating && token) {
    return <div className="content">Authenticating...</div>;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Routes>
        <Route
          path="/login/:emailFromParams?"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="/login-password"
          element={
            <PublicRoute>
              <LoginWithPassword />
            </PublicRoute>
          }
        />
        <Route
          path="/magic-login"
          element={
            <PublicRoute>
              <MagicLogin />
            </PublicRoute>
          }
        />
        <Route path="/time2book" element={<Time2Book />} />
        <Route
          path="/office-manager-call-booking"
          element={<OfficeManagerCallBooking />}
        />
        <Route path="/time2book-referral" element={<Time2BookReferral />} />
        <Route path="/time2book2" element={<Time2Book2 />} />
        <Route
          path="/which-appointment"
          element={
            <PublicRoute>
              <WhichAppointment />
            </PublicRoute>
          }
        />
        <Route
          path="/which-appointment-referral"
          element={
            <PublicRoute>
              <WhichAppointmentReferral />
            </PublicRoute>
          }
        />
        <Route
          path="/auto-login"
          element={
            <PublicRoute>
              <AutoLogin />
            </PublicRoute>
          }
        />
        <Route
          path="/consult-requirements"
          element={
            <PrivateRoute>
              <ConsultRequirements />
            </PrivateRoute>
          }
        />
        <Route
          path="/consult-requirement/:email?"
          element={
            <PublicRoute>
              <ConsultRequirement />
            </PublicRoute>
          }
        />
        <Route
          path="/admin-user-index"
          element={
            <AdminUserIndex>
              <Login />
            </AdminUserIndex>
          }
        />
        <Route
          path="/123456789"
          element={
            <PublicRoute>
              <DeleteForm />
            </PublicRoute>
          }
        />
        <Route
          path="/admin-login"
          element={
            <PublicRoute>
              <AdminLogin />
            </PublicRoute>
          }
        />
        <Route
          path="/register-child"
          element={
            <PublicRoute>
              <Navigate to="/register-secondary" replace />
            </PublicRoute>
          }
        />
        <Route
          path="/register-secondary"
          element={
            <PublicRoute>
              <RegisterSecondary />
            </PublicRoute>
          }
        />
        <Route
          path="/create-closedoor"
          element={
            <PublicRoute>
              <CreateCloseDoor />
            </PublicRoute>
          }
        />
        <Route
          path="/register"
          element={
            <PublicRoute>
              <Register />
            </PublicRoute>
          }
        />
        <Route
          path="/create"
          element={
            <PublicRoute>
              <Create />
            </PublicRoute>
          }
        />
        <Route
          path="/transition"
          element={
            <PublicRoute>
              <Transition />
            </PublicRoute>
          }
        />
        <Route
          path="/create-single"
          element={
            <PublicRoute>
              <CreateSingle />
            </PublicRoute>
          }
        />
        <Route
          path="/create-30dcr"
          element={
            <PublicRoute>
              <Create30DCR />
            </PublicRoute>
          }
        />
        <Route
          path="/create-lite"
          element={
            <PublicRoute>
              <CreateLite />
            </PublicRoute>
          }
        />
        <Route
          path="/create-double"
          element={
            <PublicRoute>
              <CreateDouble />
            </PublicRoute>
          }
        />
        <Route
          path="/create-child"
          element={
            <PublicRoute>
              <CreateChild />
            </PublicRoute>
          }
        />
        <Route
          path="/reset-password"
          element={
            <PublicRoute>
              <ResetPassword />
            </PublicRoute>
          }
        />
        <Route
          path="/reset"
          element={
            <PublicRoute>
              <SetPassword />
            </PublicRoute>
          }
        />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />
        <Route
          path="/book-call"
          element={
            <PrivateRoute>
              <BookCall />
            </PrivateRoute>
          }
        />
        <Route
          path="/watch-video"
          element={
            <PrivateRoute>
              <WatchVideo />
            </PrivateRoute>
          }
        />
        <Route
          path="/nta"
          element={
            <PrivateRoute>
              <NewForms />
            </PrivateRoute>
          }
        />
        <Route
          path="/assessments"
          element={
            <PrivateRoute>
              <AssessmentsHome />
            </PrivateRoute>
          }
        />
        <Route
          path="/ppa"
          element={
            <AuthMagicRoute>
              <PrivateRoute>
                <PpaForm />
              </PrivateRoute>
            </AuthMagicRoute>
          }
        />
        <Route
          path="/lpa"
          element={
            <AuthMagicRoute>
              <PrivateRoute>
                <LpaForm />
              </PrivateRoute>
            </AuthMagicRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path="/child-profile"
          element={
            <PrivateRoute>
              <ChildProfile />
            </PrivateRoute>
          }
        />
      </Routes>
    </Box>
  );
}
