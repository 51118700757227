import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Loader from '../loader/Loader';
import Logo from '../../app/common/Logo';
import { useEffect, useState } from 'react';
import axios from 'axios';
import {
  setUserSessionEmail,
  setUserData,
} from '../../app/services/AuthService';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getApiKey, getUrl } from '../../app/api/agent';
import { Alert } from '@mui/material';
import { renderToString } from 'react-dom/server';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

export default function Create() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [orderId, setOrderId] = useState('');
  // eslint-disable-next-line
  const [firstName, setFirstName] = useState('');
  // eslint-disable-next-line
  const [authenticating, setAuthenticating] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const [status, setStatus] = useState('loading');

  useEffect(() => {
    setEmail(searchParams.get('email').toLowerCase());
    setOrderId(searchParams.get('orderid'));
    if (email && orderId && !password) {
      autoregister();
    }
  }, [email, password]);

  const sendEmail = (token) => {
    const emailContent = renderToString(htmlTemplate(token));
    const config = {
      url: 'https://zgg7z5tys4.execute-api.us-east-2.amazonaws.com/prod/email',
      method: 'post',
      data: {
        email: email,
        content: emailContent,
        subject: 'congratulations! your account is ready',
      },
      headers: {
        'x-api-key': getApiKey(),
        'Content-Type': 'application/json',
      },
    };
    axios(config)
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const submitHandler = async (event) => {
    event.preventDefault();
    setStatus('loading');

    await resetPasswordWithOrderId();

    login(password, false);
  };
  const htmlTemplate = (token) => {
    return (
      <html>
        <head>
          <title>
            Welcome to the Pompa Program - Account Creation Success!
          </title>
        </head>
        <body>
          <p>Dear {firstName},</p>
          <p>
            We are delighted to inform you that your account with the Pompa
            Program has been successfully created. To complete the setup process
            and access your account, please click the link below to set your
            password:
          </p>
          <p>
            <a
              href={`https://apply.pompaprogram.com/reset?token=${token}&email=${email}`}
            >
              https://apply.pompaprogram.com/reset
            </a>
          </p>
          <p>
            We look forward to having you as a member of our community and thank
            you for choosing the Pompa Program.
          </p>
          <p>Best,</p>
          <p>The Pompa Program Team</p>
        </body>
      </html>
    );
  };

  const autoregister = () => {
    const config = {
      url: getUrl() + '/autoregister',
      method: 'post',
      data: {
        email: email,
        orderId: orderId,
      },
      headers: {
        'x-api-key': getApiKey(),
        'Content-Type': 'application/json',
      },
    };
    axios(config)
      .then((response) => {
        setPassword(response.data.password);

        login(response.data.password, true);
      })
      .catch((error) => {
        setAuthenticating(false);
        if (error.response.status === 409) {
          if (!error.response.data.hasResetPassword) {
            setTimeout(() => {
              setStatus('user already exist');
            }, 1000);
          } else {
            setErrorMessage(
              'User already exist, please login or reset password'
            );
            //redirect to home after 2 seconds
            setTimeout(() => {
              navigate('/');
            }, 2000);
          }
        } else if (
          error.response.status === 401 ||
          error.response.status === 403
        ) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage(error.response.data.message);
        }
      });
  };

  const resetPasswordWithOrderId = async () => {
    const config = {
      url: getUrl() + '/reset-with-order-id',
      method: 'post',
      data: {
        orderId: orderId,
        email: email,
        password: password,
      },
      headers: {
        'x-api-key': getApiKey(),
        'Content-Type': 'application/json',
      },
    };
    await axios(config)
      .then((response) => {
        if (response.data.message === 'Password updated') {
          console.log(response.data);
        }
      })
      .catch((error) => {});
    return true;
  };
  const login = (loginPassword, sendEmailFlag) => {
    const config = {
      url: getUrl() + '/login',
      method: 'post',
      data: {
        email: email,
        password: loginPassword,
      },
      headers: {
        'x-api-key': getApiKey(),
        'Content-Type': 'application/json',
      },
    };
    axios(config)
      .then((response) => {
        setUserSessionEmail(response.data.user.email, response.data.token);
        setUserData(response.data.user);
        if (sendEmailFlag) {
          sendEmail(response.data.token);
        }
        setTimeout(() => {
          navigate('/book-call');
        }, 3000);
      })
      .catch((error) => {
        setAuthenticating(false);
        if (error.response.status === 401 || error.response.status === 403) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage(
            'There has been a problem with the request, please try again later'
          );
        }
      });
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        backgroundColor: 'background.paper',
        padding: 5,
        marginTop: 5,
      }}
    >
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Logo />
        <Typography component="h1" variant="h5">
          Creating your account
        </Typography>
        <Typography component="h2" variant="h5">
          please stand by...
        </Typography>
        <Loader status={status} />
      </Box>
      {status === 'user already exist' && (
        <Box
          component="form"
          onSubmit={submitHandler}
          noValidate
          sx={{ mt: 1 }}
        >
          <Typography
            sx={{
              marginTop: 3,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            component="h3"
            variant="h5"
          >
            please set your password
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={authenticating}
            sx={{ mt: 3, mb: 2 }}
          >
            Set Password
          </Button>
        </Box>
      )}

      <div style={{ marginTop: '10px' }}>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      </div>
    </Container>
  );
}
